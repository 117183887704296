export const usePcCassette = () => {
  const onClickDetailLink = (event, link) => {
    // target="_blank" のリンククリックの場合、無効化
    if (event.target.hasAttribute('target') && event.target.target === '_blank') {
      return;
    }

    // Vodエリアの場合、無効化
    if (isInSpecificElm(event.target, 'p-content-cassette-vod')) {
      return;
    }

    // Vodエリアの場合、無効化
    if (isInSpecificElm(event.target, 'p-content-cassette-wide__vod')) {
      return;
    }

    // Drag&Dropの場合、無効化
    if (isInSpecificElm(event.target, 'p-content-cassette__actions')) {
      return;
    }

    // VodページにDrag&Dropの場合、無効化
    if (isInSpecificElm(event.target, 'p-content-cassette-wide__actions')) {
      return;
    }

    // MarkEditor内要素のクリックを除き、ページ遷移する
    if (!isInSpecificElm(event.target, 'c-marks-editor')) {
      window.location.href = link;
    }
  };

  const isInSpecificElm = (elm, targetElm) => {
    const targetReg = new RegExp(targetElm);
    if (elm.className.match(/js-cassette/)) {
      return false;
    } else {
      if (elm.className.match(targetReg)) {
        return true;
      } else {
        return isInSpecificElm(elm.parentElement, targetElm);
      }
    }
  };

  return {
    onClickDetailLink,
    isInSpecificElm
  };
};
